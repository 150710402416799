<script setup>
import AppCard from "@/components/AppCard";
import AppPlan from "@/components/Plans/PlanBlueStandard";
import AppRow from "@/components/AppRow";
import AppButton from "@/components/AppButton";
import { onMounted, ref, computed, defineProps, defineEmits } from "vue";
import { store } from "@/store";

// Api
import useApi from "@/composables/useApi";
const { user, plans } = useApi();

const props = defineProps({
    state: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(["next", "previous", "click", "end"]);

const loading = computed(() => store.APP_LOADING);
const indicePlan = ref(1);
const listPlans = ref([]);
const plan_id = ref(null);
const sales_catalog_id = ref(null);

const backStep = async () => {
    console.log("backStep...");
    emit("previous");
};

const nextStep = async () => {
    console.log("nextStep...");
    plan_id.value = listPlans.value[indicePlan.value].id;
    sales_catalog_id.value = listPlans.value[indicePlan.value];
    store.sales_catalog_id = sales_catalog_id.value;
    localStorage.setItem('sales_catalog_id', JSON.stringify(sales_catalog_id.value));
    // console.log(sales_catalog_id.value);
    emit("next");
};

const connectionPublicUser = async () => {
  try {
    const data = await user.publicUser();
    return data;
  } catch (error) {
    console.log(error);
  }
}

const getPlans = async () => {
    try {
        const data = await plans.getPlans();
        return data.payload;
    } catch (error) {
        console.log(error);
    }
};

onMounted(async () => {
    try {
        store.APP_LOADING = true;
        const connectUser = await connectionPublicUser();
        listPlans.value = await getPlans();
    } catch (error) {
        console.log(error);
    }finally{
        store.APP_LOADING = false;
    }
});
</script>
<template lang="pug">
AppCard
  h1.text-center.title-step {{$t('wizard_contract.plans.title')}}
  .plan-container.plan-box
    AppPlan(v-for="(plan, index) in listPlans" v-bind="plan" :key="index" :active="indicePlan === index" @click="indicePlan = index")
  AppRow.mt-5.btns-steps
    AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_back')" @click="backStep")
    AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_send')" :loading="loading" :disabled="loading" @click="nextStep")
</template>
<style lang="scss" scoped>
.bg-gignetgreen-500 {
  background-color: rgba(21, 199, 208, var(--tw-bg-opacity));
}
.bg-gignetgreen-500:hover {
  background-color: rgba(18, 172, 180, var(--tw-bg-opacity));
}

.plan-box {
  margin-top: 10px;
}

.title-step {
  font-weight: bold;
  color: #2a5aad;
  margin-bottom: 20px;
}

.plan-container {
  @apply grid grid-flow-row gap-x-8 gap-y-10 px-6;
  @screen md {
    @apply grid-cols-3;
  }
}

.btns-steps {
  margin-top: 30px;
}
</style>