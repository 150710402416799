<script setup>
import AppCard from "@/components/AppCard";
import AppRow from "@/components/AppRow";
import AppButton from "@/components/AppButton";
import AppFormInput from "@/components/Form/AppFormInput";
import AppFileUpload from "@/components/Form/AppFileUpload";
import AppCheckbox from "@/components/Form/AppCheckbox";
// import getFormFields from "@/utils/getFormFields";

import { onMounted, ref, computed, defineProps, defineEmits } from "vue";
import { store } from "@/store";
import { useRoute } from "vue-router";

// Api
import useApi from "@/composables/useApi";
const { user, contract } = useApi();

const route = useRoute();

const props = defineProps({
    state: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(["next", "previous", "click", "end"]);

const loading = computed(() => store.APP_LOADING);
const sales_catalog_id = computed(() => store.sales_catalog_id);
const checkvalue = ref(false);
const checkValueConfirm = ref(false);
const checkValueLocation = ref(false);

const catalog_uso_cfdi = ref([]);
const catalog_regimen_fiscal = ref([]);
const catalog_ciudad_cobertura = ref([]);
const catalog_asentamiento = ref([]);
const catalog_buildings = ref([]);
//
const identificacion_file = ref(null);
const comprobante_domicilio_file = ref(null);
//
const uso_cfdi = ref(null);
const regimen_fiscal = ref(null);
const ciudad_cobertura_id = ref(2);
const asentamiento_id = ref(null);
const build_id = ref(null);
const account_setting_id = ref(null);

const currency_id = 33;
//
const filtered_asentamiento = computed(() => {
    return catalog_asentamiento.value.filter(item => item.ciudad_cobertura_id == ciudad_cobertura_id.value);
});

const filtered_buildings = computed(() => {
    return catalog_buildings.value.filter(item => item.asentamiento_id == asentamiento_id.value);
});

const id_vendedor = computed(() => {
    return route?.query?.id;
});

const get_catalog_uso_cfdi = async () => {
    try {
        catalog_uso_cfdi.value = await contract.getUsoCFDI();
    } catch (error) {
        console.log(error);
    }
}

const get_catalog_regimen_fiscal = async () => {
    try {
        catalog_regimen_fiscal.value = await contract.getRegimenFiscal();
    } catch (error) {
        console.log(error);
    }
}

const get_catalog_ciudad_cobertura = async () => {
    try {
        const data = await contract.get_ciudad_cobertura();
        catalog_ciudad_cobertura.value = data.payload;
    } catch (error) {
        console.log(error);
    }
}

const get_catalog_asentamiento = async () => {
    try {
        const data = await contract.get_asentamiento();
        catalog_asentamiento.value = data;
    } catch (error) {
        console.log(error);
    }
}

const get_catalog_buildings = async () => {
    try {
        const data = await contract.get_buildings();
        catalog_buildings.value = data;
    } catch (error) {
        console.log(error);
    }
}

const get_account_setting = async () => {
    try {
        const team_id = 1;
        const data = await contract.get_account_setting(currency_id, team_id);
        account_setting_id.value = data.payload[0].customer_account_id[0];
        // console.log(data);
    } catch (error) {
        console.log(error);
    }
}

const backStep = async () => {
    console.log("backStep...");
    emit("previous");
};

const nextStep = async () => {
    console.log("nextStep...");
    emit("next");
};

onMounted(async () => {
    try {
        store.APP_LOADING = true;
        await get_catalog_uso_cfdi();
        await get_catalog_regimen_fiscal();
        await get_catalog_ciudad_cobertura();
        await get_catalog_asentamiento();
        await get_catalog_buildings();
        await get_account_setting();
    } catch (error) {
        console.log(error);
    }finally{
        store.APP_LOADING = false;
    }
});

const getFormFieldsCustom = async (e) => {
    e.preventDefault();
    const formElement = e.target;
    const formData = new FormData(formElement);
    const formObject = Object.fromEntries(formData.entries());
    const fields = formObject;

    // Get all checkboxes from the form
    const checkboxes = formElement.querySelectorAll('input[type="checkbox"]');
    
    // Add checkbox values to fields object
    checkboxes.forEach((checkbox) => {
        fields[checkbox.name] = checkbox.checked;
    });

    // Get all file inputs and convert to base64
    const fileInputs = formElement.querySelectorAll('input[type="file"]');
    
    for (const fileInput of fileInputs) {
        if (fileInput.files[0]) {
            const file = fileInput.files[0];
            const base64 = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(file);
            });
            const string_base64 = base64.split(',')[1];
            fields[fileInput.name] = string_base64;
        }
    }

    return fields;
}

const onSubmit = async (e) => {
    try {
        console.log("onSubmit...");
        store.APP_LOADING = true;
        const fields = await getFormFieldsCustom(e);
        console.log(fields);
        // Creando el partner
        const fields_partner = {
            name: fields.name,
            email: fields.email,
            mobile: fields.mobile,
            phone: fields.mobile,
            cliente: true,
            razon_social: checkvalue.value ? fields.razon_social : fields.name,
            vat: checkvalue.value ? fields.vat : "XAXX010101000",
            zip: checkvalue.value ? fields.zip : "77500",
            street_name: checkvalue.value ? fields.street_name : "Indefinido",
            street_number: checkvalue.value ? fields.street_number : "Indefinido",
            street_number2: checkvalue.value ? fields.street_number2 : "Indefinido",
            l10n_mx_edi_fiscal_regime: checkvalue.value ? fields.l10n_mx_edi_fiscal_regime : "616",
            l10n_mx_edi_usage: checkvalue.value ? fields.l10n_mx_edi_usage : "S01",
        }
        

        console.log(fields_partner);

        const data_partner = await contract.create_partner(fields_partner);
        console.log("--------------------------------");
        console.log(data_partner);
        console.log("--------------------------------");

        const partner_id = data_partner.payload[0];

        console.log(partner_id);

        // Creando el lead

        const fields_lead = {
            name: fields.name,
            partner_id: partner_id,
            team_id: 1,
            phone: fields.mobile,
            user_id: 2,
            asesor_preventa: fields.asesor_preventa,
            ciudad_cobertura_id: !checkValueLocation.value ? ciudad_cobertura_id.value : null,
            asentamiento_id: !checkValueLocation.value ? asentamiento_id.value : null,
            build_id: !checkValueLocation.value ? build_id.value : null,
            calle_servicio: fields.calle_servicio,
            num_ext_servicio: fields.num_ext_servicio,
            num_int_servicio: fields.num_int_servicio,
            cp_servicio: fields.cp_servicio,
            description: fields.description,
            identificacion_file: fields.identificacion_file,
        }

        console.log(fields_lead);

        const data_lead = await contract.create_lead(fields_lead);
        console.log("--------------------------------");
        console.log(data_lead);
        console.log("--------------------------------");

        const lead_id = data_lead.payload[0];
        console.log(lead_id);

        // Convertir el lead a oportunidad
        const data_lead_opportunity = await contract.update_lead(lead_id, {type: "opportunity", stage_id: 1});

        console.log("--------------------------------");
        console.log(data_lead_opportunity);
        console.log("--------------------------------");

        // Crear la orden de venta del plan de internet
        const fields_sale_order = {
            opportunity_id: lead_id,
            partner_id: partner_id,
            sales_catalog_id: sales_catalog_id.value.sales_catalog_id,
            is_autorizado: true,
            state: "draft",
            duration_contract: parseInt(sales_catalog_id.value.plazo_minimo_meses),
            customer_account_id: account_setting_id.value
        }

        const data_sale_order = await contract.create_sale_order(fields_sale_order);

        const sale_order_id = data_sale_order.payload[0];
        console.log(sale_order_id);

        // Crear la linea de la orden de venta del plan de internet
        const fields_sale_order_line = {
            order_id: sale_order_id,
            name: `${sales_catalog_id.value.nombre_comercial} - ${sales_catalog_id.value.product_template_name}`,
            product_id: sales_catalog_id.value.product_id,
            product_uom_qty: 1,
            product_uom: 21,
            price_unit: sales_catalog_id.value.renta_mensual_sin_iva,
        }

        // Crear la orden de venta de contratacion y/o instalacion
        const data_sale_order_contracting = await contract.generate_sales_order_for_contracting(lead_id, currency_id);
        console.log(data_sale_order_contracting);

        const data_sale_order_line = await contract.create_sale_order_line(fields_sale_order_line);
        console.log(data_sale_order_line);

        const sale_order_line_id = data_sale_order_line.payload[0];
        console.log(sale_order_line_id);

        // Confirmar la orden de venta
        const data_confirm_sale_order = await contract.confirm_sale_order(sale_order_id);
        console.log(data_confirm_sale_order);

        // Convertir la oportunidad a ganada
        const data_won_lead = await contract.update_lead(lead_id, {stage_id: 4});
        console.log(data_won_lead);

        // Guardar los datos en el store
        store.partner_id = partner_id;
        store.lead_id = lead_id;
        store.sale_order_id = sale_order_id;
        store.sale_order_line_id = sale_order_line_id;

        // Guardar los datos en el localStorage
        localStorage.setItem("lead_id", lead_id);
        localStorage.setItem("sale_order_id", sale_order_id);

        emit("next");
    } catch (error) {
        console.error("Error in onSubmit:", error);
        throw error;
    }finally{
        store.APP_LOADING = false;
    }
};

</script>
<template lang="pug">
//- pre {{ state.plan }}
//- pre {{ account_setting_id }}
//- pre {{ sales_catalog_id }}
//- pre {{ catalog_uso_cfdi }}
//- pre {{ catalog_regimen_fiscal }}
//- pre {{ catalog_ciudad_cobertura }}
//- pre {{ catalog_asentamiento }}
//- pre {{ catalog_buildings }}
form(@submit.prevent="onSubmit")
    AppCard
        //- h1.mt-2 Plan
        h2.mt-2 Plan de internet seleccionado
        .row
            div(style="width: 100%;")
                .row.mb-2
                    .card-plan(style="background-color: #2a5aad; border-radius: 8px; padding: 1.5rem; width: 100%;")
                        .plan-details(style="color: white; display: flex; justify-content: space-between; align-items: center; gap: 1rem;")
                            .plan-name(style="font-size: 1.5rem; font-weight: bold;") {{ sales_catalog_id.nombre_comercial }} |
                            .plan-speed(style="display: flex; align-items: center; gap: 0.5rem;")
                                span(style="font-size: 1.25rem;") Velocidad:
                                span(style="font-size: 2rem; font-weight: bold;") {{ sales_catalog_id.velocidad_incluida }}
                                span(style="font-size: 1.25rem;") Mbps
                            .plan-price(style="display: flex; align-items: center; gap: 0.25rem;")
                                span(style="font-size: 1.25rem;") $
                                span(style="font-size: 2rem; font-weight: bold;") {{ sales_catalog_id.renta_mensual_con_iva }}
                                span(style="font-size: 1.25rem;") MXN
                            .plan-price(style="display: flex; align-items: center; gap: 0.25rem;")
                                span(style="font-size: 1.25rem;") MENSUAL
        h1 {{$t('contract.contact_form.form_name')}}
        .row
            AppFormInput(type="text" :label="$t('contract.contact_form.name')" name="name" required)
            AppFormInput(type="text" :label="$t('contract.contact_form.id_vendedor')" name="asesor_preventa" :value="id_vendedor" :readonly="!!id_vendedor")
        .row
            AppFormInput(type="email" :label="$t('contract.contact_form.email')" name="email" required)
            .row
                AppFormInput(type="text" :label="$t('contract.contact_form.mobile')" name="mobile" required)
                AppFormInput(type="number" :label="$t('contract.address_form.zip_code')" name="zip" required)
        .row
            AppFileUpload.mb-4(
            icon="user-square"
            name="identificacion_file"
            :label="$t('contract.contact_form.identificacion')"
            )
            //- AppFileUpload.mb-4(
            //- icon="map"
            //- name="comprobante_domicilio_file"
            //- :label="$t('contract.contact_form.comprobante')"
            //- )
        h1.mt-2 Datos fiscales
        h2.mt-2 Si deseas facturar, por favor ingresa los siguientes datos
        .row.mt-4
            AppCheckbox(:label="$t('contract.contact_form.invoice_check')"  v-model="checkvalue")
        div(v-show="checkvalue")
            .row
                AppFormInput(type="text" :label="$t('contract.contact_form.business_name')" name="razon_social" :required="checkvalue")
                AppFormInput(type="text" :label="$t('contract.contact_form.identification')" name="vat" :required="checkvalue" pattern="[A-ZÑ&amp;]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z0-9]?[A-Z0-9]?[0-9A-Z]?")
            .row
                div
                    span(class="label") Regimen Fiscal
                    select(v-model="regimen_fiscal" class="input" name="l10n_mx_edi_fiscal_regime")
                        option(:value="item.value" v-for="item in catalog_regimen_fiscal" :key="item.value")
                            | {{item.label}}
                div
                    span(class="label") Uso CFDI
                    select(v-model="uso_cfdi" class="input" name="l10n_mx_edi_usage")
                        option(:value="item.value" v-for="item in catalog_uso_cfdi" :key="item.value")
                            | {{item.label}}
            .row
                AppFormInput(type="text" label="street_name" name="street_name" :required="checkvalue")
                AppFormInput(type="text" label="street_number" name="street_number" :required="checkvalue")
                AppFormInput(type="text" label="street_number2" name="street_number2" :required="checkvalue")
        h1.mt-2 {{$t('contract.address_form.form_name')}}
        h2.mt-2 Selecciona la ubicación en los listados de cobertura
        .row.mt-4
            AppCheckbox(label="Mi ubicación no se encuentra en la lista"  v-model="checkValueLocation" name="check_location")
        .row(v-show="!checkValueLocation")
            div
                span(class="label") Ciudad
                select(v-model="ciudad_cobertura_id" class="input" name="ciudad_cobertura_id")
                    option(:value="item.id" v-for="item in catalog_ciudad_cobertura" :key="item.id")
                        | {{item.name}}
            div
                span(class="label") Asentamiento
                select(v-model="asentamiento_id" class="input" name="asentamiento_id")
                    option(:value="item.id" v-for="item in filtered_asentamiento" :key="item.id")
                        | {{item.name}}
            div
                span(class="label") Edificio
                select(v-model="build_id" class="input" name="build_id")
                    option(:value="item.id" v-for="item in filtered_buildings" :key="item.id")
                        | {{item.name}}
        h2.mt-2 Detalles de la ubicación
        .row
            .row
                AppFormInput(type="text" :label="$t('contract.address_form.street')" name="calle_servicio" required)
                AppFormInput(type="text" :label="$t('contract.address_form.ext')" name="num_ext_servicio" required)
                AppFormInput(type="text" :label="$t('contract.address_form.int')" name="num_int_servicio")
                AppFormInput(type="number" :label="$t('contract.address_form.zip_code')" name="cp_servicio" required)
        AppFormInput(type="textarea" :label="$t('contract.address_form.aditional_info')" name="description")
        AppCheckbox(:label="$t('contract.contact_form.check')" v-model="checkValueConfirm")
        AppRow.mt-5.btns-steps
            //- button(@click="backStep") Back
            AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_back')" @click="backStep")
            AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_send')" :loading="loading" :disabled="loading || !checkValueConfirm")
</template>
<style lang="scss" scoped>
h1 {
  @apply text-bluegray-500;
  @apply font-semibold;
  @apply text-xl;
  @apply mb-3;
}
h2 {
  @apply text-bluegray-400;
  @apply font-semibold;
  @apply text-base;
  @apply mb-3;
}
.row {
  @screen md {
    @apply grid;
    @apply gap-4;
    @apply grid-flow-col;
    @apply auto-cols-fr;
  }
  &.margin {
    @apply mt-2;
    @apply mb-5;
  }
}

.bg-gignetgreen-500 {
  background-color: rgba(21, 199, 208, var(--tw-bg-opacity));
}
.bg-gignetgreen-500:hover {
  background-color: rgba(18, 172, 180, var(--tw-bg-opacity));
}
.box-address {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-badge {
  margin-left: 2px;
  margin-right: 2px;
}

.btns-steps {
  margin-top: 30px;
}

.form-input {
  @apply inline-block;
  @apply w-full;
  &__required {
    @apply text-red-400;
  }
}

.input {
  @apply mb-3;
  @apply block;
  @apply w-full;
  @apply rounded-md;
  @apply border-gray-300;
  @apply shadow-sm;
  @apply px-3;
  @apply py-2;
  @apply text-bluegray-700;
  @apply text-sm;
  @apply transition-all;
  @apply outline-none;
  &:focus:invalid {
    @apply border-red-300;
    @apply shadow-red-300-md;
  }
  &:focus {
    @apply border-gignetblue-300;
    @apply ring;
    @apply ring-gignetblue-200;
    @apply ring-opacity-50;
    @apply ring-offset-2;
  }

  &.disabled {
    @apply bg-bluegray-50;
    @apply text-bluegray-400;
  }
}

.label {
  @apply text-bluegray-400;
  @apply uppercase;
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>