<script setup>
import { onMounted, ref, computed, defineProps, defineEmits } from "vue";
import AppAlertCard from "@/components/AppAlertCard";
import AppRow from "@/components/AppRow";
import AppDescription from "@/components/AppDescription";
import { store } from "@/store";

// Api
import useApi from "@/composables/useApi";
const { contract } = useApi();

const props = defineProps({
    state: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(["next", "previous", "click", "end"]);

const lead_id = computed(() => {
    return store.lead_id ? parseInt(store.lead_id) : parseInt(localStorage.getItem("lead_id"));
});

const lead_data = ref({});

onMounted(async () => {
    try {
        store.APP_LOADING = true;
        const lead = await contract.get_lead(lead_id.value);
        lead_data.value = lead;
        // console.log(lead_data.value);
    } catch (error) {
        console.log(error);
    }finally{
        store.APP_LOADING = false;
    }
});
</script>
<template lang="pug">
//- pre {{ lead_id }}
//- pre {{ lead_data }}
div
  AppAlertCard(:title="$t('wizard_contract.end.title')" :subtitle="$t('wizard_contract.end.subtitle')" :label="$t('wizard_contract.end.label')") 
    AppRow.mb-8.text-center
      AppDescription(label="Folio" :description="lead_data.id")
      AppDescription(label="Numero de servicio" :description="lead_data.contract_id")
</template>