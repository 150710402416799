<script setup>
import pdf from "vue3-pdf";
import AppCard from "@/components/AppCard";
import AppRow from "@/components/AppRow";
import AppButton from "@/components/AppButton";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppFormInput from "@/components/Form/AppFormInput";
import { onMounted, ref, computed, defineProps, defineEmits } from "vue";
import { store } from "@/store";
import { useRoute } from "vue-router";

const route = useRoute();


// Api
import useApi from "@/composables/useApi";
const { user, contract } = useApi();

const props = defineProps({
    state: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(["next", "previous", "click", "end"]);

const loading = computed(() => store.APP_LOADING);
const contract_data = ref(null);
const contractPDF = ref(null);
const currentPage = ref(1);
const signaturePad = ref("");
const signValue = ref("");

const strokeOptions = {
      penColor: "blue",
      onBegin: () => {
        window.dispatchEvent(new Event("resize"));
      },
    };

const checkValueConfirm = ref(false);

function encode(data) {
    return encodeURIComponent(btoa(data));
}

function decode(data) {
    return parseInt(atob(decodeURIComponent(data)));
}

const lead_id = computed(() => {
    const id = route.params?.id
        ? decode(route.params.id)
        : undefined;
    
    if(id !== undefined){
        console.log("id: ", id);
        store.lead_id = id;
        localStorage.setItem("lead_id", id);
        return id;
    }else{
        console.log("store: ", store.lead_id);
        console.log("localStorage: ", localStorage.getItem("lead_id"));
        return store.lead_id ? parseInt(store.lead_id) : parseInt(localStorage.getItem("lead_id"));
    }
});

const connectionPublicUser = async () => {
  try {
    const data = await user.publicUser();
    return data;
  } catch (error) {
    console.log(error);
  }
}

const loadContract = async () => {
    try {
        const data = await contract.get_load_contract(lead_id.value);
        return data.payload;
    } catch (error) {
        console.log(error);
    }
}

onMounted(async () => {
    try {
        store.APP_LOADING = true;
        const connectUser = await connectionPublicUser();
        contract_data.value = await loadContract();
        contractPDF.value = `data:application/pdf;base64,${contract_data.value}`;
    } catch (error) {
        console.log(error);
    }finally{
        store.APP_LOADING = false;
        // Guardar el lead_id en el store y en el localStorage
        store.lead_id = lead_id.value;
        localStorage.setItem("lead_id", lead_id.value);
    }
});

const prev = () => {
    if (currentPage.value < 2) return;
    currentPage.value--;
};

const next = () => {
    if (currentPage.value > 7) return;
    currentPage.value++;
};


const clearSignature = () => {
    signaturePad.value.clearSignature();
};

const saveSignature = async () => {
    try {
        store.APP_LOADING = true;
        console.log("saveSignature");
        const { isEmpty, data } = signaturePad.value.saveSignature();
        if (isEmpty) return;
        signValue.value = data.replaceAll("data:image/png;base64,", "");
        // console.log(signValue.value);
        const sign_binary = signValue.value;
        // const sign_binary = new Uint8Array(Buffer.from(signValue.value, 'base64'));
        const result = await contract.sign_contract(lead_id.value, sign_binary);
        // console.log(result);
        emit("next", {});
    } catch (error) {
        console.log(error);
    }finally{
        store.APP_LOADING = false;
    }
}

const goNext = () => {
    emit("next");
}
</script>

<template lang="pug">
//- pre {{ store.lead_id }}
//- pre {{ lead_id }}
//- pre {{ contract_data }}
//- button(@click="goNext")
//-     | Next Test
.contract(v-if="contract_data")
  AppCard.mb-4
    .flex.justify-center
      AppRow
        .buttonPDF(@click="prev", :class="{ disabled: currentPage == 1 }")
          i.uil.uil-angle-left
        .buttonPDF(@click="next", :class="{ disabled: currentPage == 8 }")
          i.uil.uil-angle-right
    pdf(:src="contractPDF", :page="currentPage")
    AppCard.mb-4(v-if="state.lead_id")
      .contract__title Link del contrato
      AppRow
        AppFormInput(type="text", :value="contractRoute", readOnly)
        .icon__copy(@click="copyToClickBoard")
          i.uil.uil-copy
  AppCard
    .contract__title Firma
    .sing
      VueSignaturePad(ref="signaturePad", :options="strokeOptions")
      .sing__line
    AppCheckbox.mt-4(
      :label="$t('signature.check')",
      v-model="checkValueConfirm"
    )
  AppRow.mt-5
    AppButton.my-3.danger(
      :label="$t('signature.clear')",
      @click="clearSignature"
    ) 
    AppButton.my-3(
      :label="$t('signature.save')",
      :loading="loading",
      :disabled="loading || !checkValueConfirm",
      @click="saveSignature"
    )
.contract(v-else)
  AppCard
    .contract__title El contrato no se encuentra disponible, favor de contactar al departamento de ventas.
    AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_send')" @click="goNext")
</template>

<style lang="scss" scoped>
.contract {
  &.margin {
    @apply mt-2;
    @apply mb-5;
  }
  &__title {
    @apply text-bluegray-400;
    @apply uppercase;
    @apply text-sm;
    @apply font-medium;
    @apply mb-2;
    @apply inline-block;
    @apply tracking-wide;
  }
  .buttonPDF {
    @apply text-center;
    @apply h-6;
    @apply w-6;
    @apply bg-gignetgreen-200;
    @apply rounded-full;
    @apply text-gignetblue-400;
  }
  .disabled {
    @apply bg-gray-100;
    @apply text-gray-400;
    @apply cursor-not-allowed;
  }
  .icon__copy {
    @apply bg-gray-100;
    @apply w-10;
    @apply h-9;
    @apply rounded-full;
    @apply text-center;
    @apply pt-1;
    i {
      @apply text-gray-600;
    }
  }
}
.sing {
  @apply rounded-lg;
  @apply bg-gignetblue-100;
  @apply h-28;
  &__line {
    margin-top: -25px;
    @apply border-dashed;
    @apply mx-2;
    @apply border-t-2;
    @apply border-gignetblue-200;
    @apply h-4;
  }
}
</style>