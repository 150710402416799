<template lang="pug">
.plan(:class="{ active }")
  h1.nombre {{ nombre_comercial }}
  .card.box-plan
    .card__body
        h4.text-left
            | {{$t('wizard_contract.plans.prefix')}}
        h3.velocidad 
            span {{ velocidad_incluida }}
            | Mbps
        span.description(v-if="texto_tarifa_descripcion") {{ texto_tarifa_descripcion }}
  .renta
    span.renta_precio {{ numberFormatter(renta_mensual_con_iva) }}
    span.periodo MXN {{$t('wizard_contract.plans.period')}}
    span.instalacion
        | {{$t('wizard_contract.plans.install')}}: {{ instalacion_con_iva }} MXN
</template>

<script>
import { numberFormatter } from "@/utils/formatters";

export default {
  props: {
    is_promotion: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
    plazo_minimo_meses: {
      type: Number,
      default: 0,
    },
    nombre_comercial: {
      type: String,
      default: "",
    },
    velocidad_incluida: {
      type: Number,
      default: 0,
    },
    speed_promotion: {
      type: Number,
      default: 0,
    },
    instalacion_sin_iva: {
      type: Number,
      default: 0,
    },
    instalacion_con_iva: {
      type: Number,
      default: 0,
    },
    renta_mensual_con_iva: {
      type: Number,
      default: 0,
    },
    texto_tarifa_descripcion: {
      type: [String, Boolean],
      default: "",
    },
    plan_servicio_internet: {
      type: Array,
      default: () => [0, ""],
    },
  },
  setup() {
    return { numberFormatter };
  },
};
</script>

<style lang="scss" scoped>
.plan {
  @apply select-none;
  @apply border;
  @apply border-bluegray-200;
  @apply rounded-md;
  @apply py-8;
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply text-center;
  @apply ring ring-transparent;
  background-color: #2a5aad;

  @apply transition-all;
  @apply transform;
  &.active {
    @apply scale-105;
    @apply border-transparent;
    @apply ring ring-gignetgreen-400;
  }
  &:active {
    @apply scale-110;
  }
}

.nombre {
  @apply font-medium;
  @apply text-sm;
  @apply text-bluegray-400;
  @apply mb-4;
  color: white !important;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}

.plazo {
  @apply mb-4;
  @apply text-bluegray-500;
  color: white;
}

.renta {
  @apply flex;
  @apply flex-col;
  @apply text-bluegray-500;
  .renta_precio {
    font-weight: bold;
    font-size: 50px;
  }
  margin-top: 50px;
  color: white;
}

.velocidad {
  @apply text-bluegray-600;
  @apply text-2xl;
  @apply font-semibold;
  span {
    @apply text-6xl;
    @apply font-bold;
    color: #636363;
  }
}

.description {
  @apply text-bluegray-400;
  @apply text-xs;
  @apply font-light;
  margin-top: 20px;
}

.box-plan {
  background-color: white !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}

.periodo {
  font-size: 20px;
}

.instalacion {
  margin-top: 10px;
  font-weight: bold;
}
</style>
    